@import '@/styles/theme/variables.scss';

.addProjectBtnWrapper {
  float: left;
  margin-bottom: $token-spacing-6 !important;
  margin-right: $token-spacing-6;

  @media #{$usb-max-sm} {
    width: 100%;
  }
}

.documentationWrapper {
  margin-left: $token-spacing-6 !important;
}

@media #{$usb-max-sm} {
  .documentationWrapper {
    margin-left: $token-spacing-0 !important;
    
    button {
      width: 100% !important;
    }
  }
}
