@import '@/styles/theme/variables.scss';

.tableOfContentSectionWrapper {
  a {
    color: $token-background-dark;
    text-decoration: none !important;
    line-height: $token-line-height-21px;
  }
  
  div.tableOfContentSectionContent {
    font-size: $token-font-size-sm;
    font-weight: $token-font-weight-bold;
    padding-left: $token-spacing-2;
    line-height: $token-line-height-21px;
    overflow-y: auto;
    margin-top: $displayViews-MDTOCSectionWrapper-MDTOCSectionContent-margin-top;
  }

  ul {
    list-style-type: none !important;
    margin: $token-spacing-0 !important;
    padding-inline-start: $token-spacing-0 !important;
    line-height: $token-spacing-8;

    li a:hover {
      color: $token-text-color-interaction !important;
    }

    &.tableOfContentMenuList ul {
      list-style-type: none;
      // padding-inline-start: $token-spacing-4 !important;
    }
  }
}

.childrenContainer {
  a.tableOfContentChildren {
    padding-left: $token-spacing-5 !important;
  }
}

.tableOfContentSectionTitle {
  font-size: $token-font-size-14px;
  font-weight: $token-font-weight-med;
  color: $token-background-dark;
  text-transform: uppercase;
  letter-spacing: $token-spacing-xs;
  padding-left: $token-spacing-5px !important;
}

a.selectedTableOfContentTitle {
  border-left: $token-border-width-large solid $token-background-brand-interaction !important;
  color: $token-background-brand-interaction !important;
}

.tableOfContentMenuList {
  a {
    line-height: $token-line-height-loose;
    display: inline-block;
    padding: $token-spacing-small $token-spacing-small $token-spacing-small $token-spacing-5px;
    border-left: $token-border-width-large solid transparent;
    word-break: $displayViews-TOCMenuList-a-word-break;
  }

  li {
    margin: $token-spacing-4 $token-spacing-0 $token-spacing-4 $token-spacing-0;
  }
}

@media #{$usb-max-820} {
  .tableOfContentSectionWrapper {
    display: none;
  }
}

@media #{$usb-max-lg} {
  .tableOfContentSectionWrapper {
    display: none !important;
  }
}
