@import '@/styles/theme/variables.scss';

.textBlock {
  padding: $token-spacing-4 $token-spacing-8;
  line-height: $token-spacing-6;
}

.disclosureHeader {
  font-size: $token-font-size-root;
  line-height: $token-spacing-6;
  font-weight: $token-font-weight-med;
  color: $token-utility-black;
}

.disclosureShortDescription {
  font-size: $token-font-size-xs;
  line-height: $token-spacing-base;
  color: $token-utility-black;
}

.shortDescription {
  font-size: $token-font-size-xs;
  line-height: $token-spacing-base;
}

.headH5 {
  width: $token-spacing-281px;
  height: $token-spacing-21px;
  left: $token-spacing-257-5px;
  top: $token-spacing-1878-6px;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-sm;
  line-height: $token-line-height-loose;
  /* identical to box height, or 150% */
  letter-spacing: $token-spacing-xs;
  color: $token-color-grey-90;
}

.shortDescription {
  font-size: $token-spacing-4;
}

@media #{$usb-max-820} {
  .textBlock {
    padding: $token-spacing-4 $token-spacing-0;
  }
}

@media screen and (min-width: $token-breakpoint-large) {
  .layout_image {
    justify-self: end !important;
  }
}

@media (min-width: $token-breakpoint-x-large) { 
  .gridWrapper {
    max-width:$token-spacing-1650px !important;
  }
  .gridWrapperSections {
    padding-top: $token-spacing-0 !important;
    padding-bottom: $token-spacing-0 !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }
  .fullWidth {
    max-width:$token-spacing-1120px !important;
  }
}
