@import '@/styles/theme/variables.scss';

.actionLink {
  text-decoration: none !important;
  font-weight: $token-font-weight-reg !important;
  margin-right: $token-spacing-6 !important;

  @media only screen and (max-width: $token-breakpoint-medium) {
    margin-right: $token-spacing-3 !important;
  }
}

.actionLinkImage {
  margin-top: $token-spacing-small !important;
}

.deleteProjectForm {
  overflow: auto !important;
}

.actionButton {
  margin: $token-spacing-0 !important;
  padding: $token-spacing-0 !important;
  font-weight: $token-font-weight-reg !important;
  min-height: $token-spacing-0 !important;

  span {
    margin-right: $token-spacing-1 !important;

    svg {
      height: $token-spacing-14px;
      width: auto;
      margin-bottom: $token-spacing-small;
    }
  }

  margin-right: $token-spacing-7 !important;

  @media only screen and (max-width: $token-breakpoint-medium) {
    margin-right: $token-spacing-2 !important;
  }

  &:hover {
    background: none !important;
    color: $token-background-brand-interaction !important;

    span {
      color: $token-background-brand-interaction !important;
    }
  }
}

.alert{
  color: $token-background-brand-secondary-active !important;

  img{
    margin-top: $token-spacing-1 !important;
  }
}

.deleteproject_form {
  display: inline-block;

  button {
    &:focus {
      &::before {
        border: none !important;
      }
    }
  }
}