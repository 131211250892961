@import '@/styles/theme/variables.scss';

.addToProjectBtn {
  width: 100%;
}

.addToProjectForm {
  overflow: scroll !important;

  .modelDescription {
    margin-bottom: $token-spacing-7;
  }

  .formInput:not(:last-child) {
    margin-bottom: $token-spacing-7;
  }

  p.formLabel  {
    font-weight: $token-font-weight-med;
    color: $token-color-grey-90;
  }

  .statusRadiobtn {
    display: inline-flex !important;

    div div {
      padding-right: $token-spacing-7 !important;
    }
  } 

  .tooltipBox {
    height: $token-spacing-11;
    margin: $token-spacing-0;
    position: absolute;
    right: (-$token-spacing-3);
    top: $token-spacing-15px;
    width: $token-spacing-11;
  }
  .tooltipBox p {
    margin: $token-spacing-1 $token-spacing-2;
  }

  @media only screen and (min-width: $token-breakpoint-minimum) and (max-width: $token-breakpoint-medium) {
    .statusRadiobtn {
      display: block !important;
    }
  }
}

@media #{$usb-max-md}  {
  .addToProjectBtn {
    margin-top: $token-spacing-0 !important;
  }
}
