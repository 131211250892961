@import '@/styles/theme/variables.scss';

.bottomGridContainer {
  margin-bottom: $token-spacing-20 !important;
}

.productHeroBanner{
  background-color: $table-row-secondary-background-color;
}

.fullWidthColumn {
  width: 100%;
}

.heroImageBlock {
  display: flex;
}

.accessRequest {
  margin-top: $token-spacing-2;
}

.markdownContent table,
.markdownContent td,
.markdownContent th {
  color: $token-text-color-secondary;
  border-collapse: collapse !important;
  margin-bottom: 21px !important;
}

.markdownContent td,
.markdownContent th {
  padding: $token-spacing-21px !important;
  font-size: $token-font-size-sm;
  font-weight: $token-font-weight-reg !important;
  text-align: initial;
}

.markdownContent table {
  border: $token-border-width-default solid $token-border-base !important;
  width: 100% !important;
  margin-top: $token-spacing-4;

  thead {
    border: $token-border-width-default solid $token-border-base !important;
  }

  thead th {
    color: $token-text-color-primary;
    font-style: normal;
    font-weight: $token-font-weight-med !important;
    font-size: $token-font-size-sm;
    line-height: $token-line-height-21px;
    color: $token-color-grey-90;
  }

  td {
    font-size: $token-font-size-sm;
    font-weight: $token-font-weight-reg !important;
  }

  tr:nth-child(even) {
    background-color: $token-background-secondary;
  }
} 

.markdownContent td:first-child,
.markdownContent th:first-child {
  width: 23%;
}

.markdownContent td:nth-child(2),
.markdownContent th:nth-child(2) {
  padding-left: $token-spacing-0 !important;
}


.version{
  font-size: $token-font-size-sm;
  font-weight: $token-font-weight-med;
  color: $token-text-color-primary !important;
}

@media #{$usb-max-lg} {
  .heroImageBlock {
    display: none !important;
  }

  .version{
    font-size: $token-font-size-base;
    font-weight: $token-font-weight-reg;
    color: $token-text-color-secondary !important;
  }
}

.rightSidebarColumn {
  background-color: $token-background-secondary;
  padding: $token-spacing-8 !important;
  width: 100%;
}

.eachSideBarLink {
  margin-bottom: $token-spacing-4;
  font-family: $productOverviewPage-font-family !important;
  -webkit-font-smoothing: antialiased;
  color: $productOverviewPage-sidebarLink-color !important;
  font-style: normal !important;
  font-weight: $productOverviewPage-sidebarLink-font-weight !important;

  span {
    color: $productOverviewPage-sidebarLink-color !important;

    svg {
      fill: $productOverviewPage-sidebarLink-color !important;
    }
  }
}

.rightSideBarLabel {
  font-family: $productOverviewPage-rightSideBar-h2-font-family;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: $productOverviewPage-rightSideBar-h2-font-weight;
  font-size: $token-font-size-sm;
  line-height: $productOverviewPage-rightSideBar-h2-line-height;
  letter-spacing: $productOverviewPage-rightSideBar-h2-letter-spacing;
  text-transform: uppercase;
  margin-bottom: $token-spacing-3;
  color: $token-text-color-primary;
}

.dropdownLayout {
  width: 100%;
  margin-bottom: $token-spacing-4;
}

.productOverviewDropdown {
  button {
    border: $token-border-none !important;
    outline: none;
    background-color: transparent !important;
    margin:$token-spacing-0;
    padding-left: 0.2rem !important;

    span {
      color: $token-text-color-tertiary !important;
      margin: $token-spacing-0 !important;
    }

    svg {
      right: -0.07rem !important;
      margin-right: $token-spacing-1 !important;
    }

    &:focus {
      margin-left:$token-spacing-0;
    }
  }

  li, li:focus {
      border: $token-border-none !important;
      outline: none;
      width: auto; 
      background-color: $token-background-primary;

      &:hover {
        background-color: $token-background-tertiary;
        span {
          color: $token-background-brand-interaction !important;
        }
      }
  }

  ul {
    width: 100%;
  }
}

@media #{$usb-max-lg} {
  .rightSidebarColumn {
    order: 3;
    max-width: 50%;
  }
}

.gridStyles {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  margin-top: $token-spacing-8 !important;
}

@media #{$usb-max-lg} {
  .gridStyles {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
    margin-top: $token-spacing-12 !important;
  }
}

@media #{$usb-max-md} {
  .gridStyles {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    margin-top: $token-spacing-12 !important;
  }

  .rightSidebarColumn{
    max-width: 100% !important;
  }

  .buttonsStyles button {
    margin-right: $token-spacing-0 !important;
  }
}



.buttonsStyles {
  display: flex;
  margin-top: $token-spacing-4;
  margin-bottom: $token-spacing-12 !important;
}

@media #{$usb-max-sm} {
  .mainGridStyles{
    background-color: $token-background-primary!important;
  }

  .gridStyles {
    margin-top: $token-spacing-10 !important;
  }

  .buttonsStyles {
    margin-top: $token-spacing-11 !important;
    margin-bottom: $token-spacing-0!important;
    width:-webkit-fill-available;
    display: block !important;
    width: 100%;
  }

  .breadcrumb {
    display: none;
  }

  .markdownTitle {
    margin-bottom: $token-spacing-5 !important;

    p {
    font-size: $token-spacing-6 !important;
    font-weight: $token-font-weight-bold !important;
    line-height: $token-spacing-8 !important;
    }
  }
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .leftColumnContent ul li:last-of-type {
    margin-bottom: $token-spacing-6;
  }
}

.heroImageWrapper {
  position: relative;
  top: $token-spacing-5;
}

.markdownTitle {
  margin-bottom: $token-spacing-4;
  margin-top: $token-spacing-2;
  color: $productOverviewPage-h2-title-color;

  p {
    @include headH1;
    font-weight: $token-font-weight-bold;
  }
}

.markdown_left_column {
  div{
    margin-bottom: $token-spacing-8;
  }

  p {
    padding: $token-spacing-0 !important;
    font-weight: $token-font-weight-reg;
    font-size: $token-font-size-root;
  }

  p,h2 {
    margin-bottom: $token-spacing-3;
  }

  h1 {
    @include headH1;
  }

  h2 {
    font-size: $token-font-size-2xl;
    font-weight: $token-font-weight-med;
    line-height: $productOverviewPage-h2-line-height;
    margin-bottom: $token-spacing-3;
  }

  h3 {
    font-size: $token-font-size-lg;
    font-weight: $token-font-weight-med;
    line-height: $token-spacing-6;
    letter-spacing: $token-spacing-0;
    margin-bottom: $token-spacing-3;
  }

  h4 {
      //styleName: Subheader Large;
    margin-bottom: $token-spacing-3;
    font-size: $token-font-size-base;
    font-weight: $token-font-weight-med;
    line-height: $token-line-height-loose;
    letter-spacing: $token-spacing-0;
  }

  h5 {
    //styleName: Superhead;
    font-size: $token-font-size-sm;
    font-weight: $token-font-weight-med;
    line-height: $token-line-height-loose;
    letter-spacing: $token-letter-spacing-loose;
  }

  ul {
    @include listUl;
    padding-inline-start:$token-spacing-10;

    li p {
      margin-bottom: $token-spacing-0 !important;
    }
  }

  ol {
    @include listOl;
    padding-inline-start:$token-spacing-10;
  }

  pre,
  ol,
  ul {
    width: 100%;
  }
}

.markdownSummary {
  font-size: $token-font-size-lg;
  font-weight: $token-font-weight-med;
  line-height: $token-font-size-xl;
  margin-bottom: $token-spacing-6;
  color: $token-text-color-primary;
}


@media #{$usb-max-sm} {
 .buttonsStyles {
  display: block !important;
  width: 100%;

  button {
    width: 100% !important;

    &:nth-child(2) {
      margin-left: $token-spacing-0 !important;
    }
  }
 }  
}



@media (min-width: $token-breakpoint-x-large) { 
  .mainGridStyles, .bottomGridContainer {
    max-width:$token-spacing-1650px !important;
  } 
  
  .fullWidthColumn, .gridStyles {
    max-width:$token-spacing-1120px !important;
  }
}
