@import '@/styles/theme/variables.scss';

h2.promoteTitle {
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-lg;
  line-height: $token-line-height-loose;
  color: $token-text-color-brand-primary;
  margin-bottom: $token-spacing-4;
}

.promoteProjectForm{overflow: auto;}

p.promoteDescription {
  color: $token-text-color-secondary;
  margin-bottom: $token-spacing-4;
}

.promoteButton {
  margin: $token-spacing-0 !important;
  @media #{$usb-max-sm} {
    width: 100% !important;
  }
}

// Smaller than MD screen size
@media #{$usb-max-md} {
  .promoteComponent {
    display: block;
    width: 100%;

   .promoteButton {
      width: 100%;
      margin-bottom: $token-spacing-11 !important;
    }
  }
}

@media only screen and (min-width: $token-breakpoint-medium) and (max-width: $token-breakpoint-large) {
  .promoteUATSection {
    margin-right: $token-spacing-20 !important;
  }
}